<template>
  <div class="p-3 pb-5 mb-5">
    <div class="my-4">
      <div class="mb-3 d-flex justify-content-between py-3">
        <p class="robo-24-500 txt-black">{{ $t('lbl_edit_meeting_information') }}</p>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="goToList"
          class="cursor-pointer"
        >
          <path
            d="M6 18.4998L17.9998 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.9998 18.4998L6 6.5"
            stroke="#292D32"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="mb-5">
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 required mt-1 txt-black">{{ $t('lbl_room_name') }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input type="text" class="form-control border radius-10" v-model="form.name" />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{ $t('lbl_description') }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <CkeditorNormal
            @onEditorInput="
          (t) => {
            form.description = t;
          }
        "
            :prop_text="form.description"
            :placeholder="'Mô tả'"
            :class_style="'bg-white p-0 txt-black'"
            :id="'inputTextChat'"
          ></CkeditorNormal>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 required mt-1 txt-black">{{ $t('lbl_time') }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <div class="row">
            <div class="col-md-6 d-flex">
              <input
                type="date"
                class="form-control w-50 border radius-10 mr-1"
                v-model="form.date"
                :min="today"
              />
              <input
                type="time"
                class="form-control w-50 border radius-10"
                v-model="form.start_time"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{ $t('lbl_repeat') }}</label>
        </div>
        <div class="col-md-6">
          <select v-model="form.repeat_type" class="form-select border radius-10">
            <option :value="0">{{ $t('lbl_not_repeat') }}</option>
            <option :value="1">{{ $t('lbl_daily') }}</option>
            <option :value="2">{{ $t('lbl_weekly') }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-5 col-xl-3">
          <label class="robo-18-400 mt-1 txt-black">{{ $t('lbl_location') }}</label>
        </div>
        <div class="col-lg-8 col-md-7 col-xl-9">
          <input type="text" class="form-control border radius-10" v-model="form.location" />
        </div>
      </div>
      <!-- <div class="row mb-3">
          <div class="col-lg-4 col-md-5 col-xl-3">
            <label class="robo-18-400 mt-1 txt-black">Mật khẩu</label>
          </div>
          <div class="col-lg-8 col-md-7 col-xl-9">
            <input
              type="text"
              class="form-control border radius-10"
              v-model="form.password"
            >
          </div>
      </div>-->
      <div class="d-flex justify-content-end align-items-center py-3">
        <button
          type="button"
          class="btn bg-pri bd-pri text-white px-5"
          @click="onSubmit"
        >{{ $t('lbl_save') }}</button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import CkeditorNormal from '../../components/Editors/CkeditorNormal.vue'
export default {
  name: 'MeetRoomUpdate',
  components: { CkeditorNormal },
  data () {
    return {
      form: {
        name: '',
        date: '',
        start_time: null,
        end_time: '23:59:59',
        repeat_type: 0,
        location: '',
        description: '',
        meet_room: null
      },
      today: window.moment().format('YYYY-MM-DD')
    }
  },
  mounted () {
    this.form.date = this.today
    this.showMeetRoom()
  },
  methods: {
    goToList () {
      this.$router.push({ name: 'Meeting' })
    },
    async showMeetRoom () {
      await this.$rf
        .getRequest('DoctorRequest')
        .showMeetRoom(this.$route.params.id)
        .then(r => {
          this.meet_room = r.data
          if (!this.canModifyMeet(this.meet_room?.members)) {
            this.$toast.open({
              message: 'Bạn không có quyền chỉnh sửa',
              type: 'warning'
            })
            this.$router.go(-1)
          }
          this.form = {
            name: r.data.name,
            date: r.data.date,
            start_time: r.data.start_time,
            end_time: r.data.end_time,
            repeat_type: r.data.repeat_type,
            location: r.data.location,
            description: r.data.description || ''
          }
        })
    },
    async onSubmit () {
      let form = this.form
      if (!form.date) {
        alert('Vui lòng chọn ngày diễn ra')
        return false
      } else {
        form.date = window.moment(form.date).format('DD-MM-YYYY')
      }
      if (!form.start_time) {
        delete form.start_time
        delete form.end_time
      } else {
        form.start_time = window
          .moment(form.start_time, 'HH:mm')
          .format('HH:mm:ss')
        form.end_time = window
          .moment(form.end_time, 'HH:mm')
          .format('HH:mm:ss')
      }
      await this.$rf
        .getRequest('AuthRequest')
        .updateMeetRoom(this.$route.params.id, form)
        .then(r => {
          this.$router.push({
            name: 'ListMdt',
            query: { meetid: r.data.id, type: 'HOP' }
          })
        })
    },
    getDate (date_time) {
      return window.moment(date_time).format('YYYY-MM-DD')
    }
  }
}
</script>
  
  <style scoped>
.form-control {
  background: #fff;
  height: 42px;
  font-size: 16px;
  color: black;
}
</style>