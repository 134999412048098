<template>
  <div
    class="mdt-container"
    :class="!locked && !loading && room ? 'bg-252A30' : ''"
  >
    <!-- locked -->
    <div class="px-4 pt-3" v-if="locked && !loading">
      <div class="d-flex align-items-center">
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2"
        >
          <path
            d="M30.5401 19.0954C29.9851 19.0954 29.5201 18.6754 29.4601 18.1204C29.1001 14.8204 27.3301 11.8504 24.6001 9.96035C24.1051 9.61535 23.9851 8.94035 24.3301 8.44535C24.6751 7.95035 25.3501 7.83035 25.8451 8.17535C29.1001 10.4404 31.2001 13.9804 31.6351 17.8954C31.6951 18.4954 31.2601 19.0354 30.6601 19.0954C30.6151 19.0954 30.5851 19.0954 30.5401 19.0954Z"
            fill="#20419B"
          />
          <path
            d="M5.6097 19.1698C5.5797 19.1698 5.5347 19.1698 5.5047 19.1698C4.9047 19.1098 4.4697 18.5698 4.5297 17.9698C4.9347 14.0548 7.0047 10.5148 10.2297 8.23481C10.7097 7.88981 11.3997 8.00981 11.7447 8.48981C12.0897 8.98481 11.9697 9.65981 11.4897 10.0048C8.7897 11.9248 7.0347 14.8948 6.7047 18.1798C6.6447 18.7498 6.1647 19.1698 5.6097 19.1698Z"
            fill="#20419B"
          />
          <path
            d="M23.9851 31.65C22.1401 32.535 20.1602 32.985 18.0902 32.985C15.9302 32.985 13.8752 32.505 11.9552 31.53C11.4152 31.275 11.2052 30.615 11.4752 30.075C11.7302 29.535 12.3902 29.325 12.9302 29.58C13.8752 30.06 14.8802 30.39 15.9002 30.585C17.2802 30.855 18.6902 30.87 20.0702 30.63C21.0902 30.45 22.0952 30.135 23.0252 29.685C23.5802 29.43 24.2401 29.64 24.4801 30.195C24.7501 30.735 24.5401 31.395 23.9851 31.65Z"
            fill="#20419B"
          />
          <path
            d="M18.075 3.01514C15.75 3.01514 13.845 4.90514 13.845 7.24514C13.845 9.58514 15.735 11.4751 18.075 11.4751C20.415 11.4751 22.305 9.58514 22.305 7.24514C22.305 4.90514 20.415 3.01514 18.075 3.01514Z"
            fill="#20419B"
          />
          <path
            d="M7.57497 20.8052C5.24997 20.8052 3.34497 22.6952 3.34497 25.0352C3.34497 27.3752 5.23497 29.2652 7.57497 29.2652C9.91497 29.2652 11.805 27.3752 11.805 25.0352C11.805 22.6952 9.89997 20.8052 7.57497 20.8052Z"
            fill="#20419B"
          />
          <path
            d="M28.4248 20.8052C26.0998 20.8052 24.1948 22.6952 24.1948 25.0352C24.1948 27.3752 26.0848 29.2652 28.4248 29.2652C30.7648 29.2652 32.6548 27.3752 32.6548 25.0352C32.6548 22.6952 30.7648 20.8052 28.4248 20.8052Z"
            fill="#20419B"
          />
        </svg>
        <p class="robo-24-500 txt-pri mb-0">{{$t('multidisciplinary_board.lbl_mdt_title')}}</p>
      </div>

      <div class="my-4">
        <div
          class="d-flex align-items-center txt-grey-600 cursor-pointer"
          @click="goToList"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2"
          >
            <path
              d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
              fill="#616673"
            />
          </svg>
          <span>{{$t('multidisciplinary_board.lbl_back')}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-2">
          <p class="robo-24-500 txt-black text-center">{{$t('multidisciplinary_board.lbl_join_mdt')}}</p>
          <p class="robo-24-500 txt-black text-center" v-if="room">
            {{ room.name }}
          </p>
          <p class="robo-18-400 txt-black text-center mb-4" v-if="room">
            {{ getDateString(room) }}
          </p>
        </div>
        <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <p class="robo-18-400">{{$t('multidisciplinary_board.lbl_enter_password')}}</p>
          <input
            type="text"
            v-model="password"
            class="form-control border mb-4"
            @keyup.enter="joinMdtRoom"
          />
          <button class="btn bg-pri bd-pri text-white w-100" @click="joinMdtRoom">
            {{$t('multidisciplinary_board.lbl_join_now')}}
          </button>
        </div>
      </div>
    </div>
    <!-- locked -->
    <div v-show="!locked && !loading && room" ref="outer__zoom">
      <div v-bind:style="{ zoom: zoomValue + '%' }">
        <div class="mdt-wraper m-auto" id="mdtWraper" ref="mdtWraper">
          <div v-if="!locked && !loading && room && room.id && room_name">
            <TwilioVideoCall
              :room_name="room_name"
              :room_info="room"
              :room_members="room_members"
              :checkin_members="checkin_members"
              :mdt_diseases="mdt_diseases"
              :currDisease="currDisease"
              :show_info="show_info"
              :show_disease="show_disease"
              :started_seconds="started_seconds"
              @refreshDisease="refreshDisease"
              @showBienBan="showBienBan"
              @showTTTQM="showTTTQM"
              @showDiseaseDetail="showDiseaseDetail"
              @showViewBienBan="showViewBienBan"
              @getMdtDiseases="onGetMdtDiseases"
              @hideInfo="hideInfo"
              @showInfo="showInfo"
              @hideDisease="hideDisease"
              @showDisease="showDisease"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-center"
      v-if="loading"
    >
      <div class="spinner-border mt-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <BienBan
      :currDisease="mdt_disease_for_concluding"
      :room_info="room"
      @refresh="refreshDisease"
      :checkin_members="checkin_members"
    />
    <div
      class="modal fade"
      id="modalTTTQM"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalTTTQMLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document" v-show="mdt_disease_for_concluding">
        <div class="modal-content p-3">
          <div class="d-flex justify-content-between pb-3 border-bottom mb-3">
            <h5 class="modal-title" id="modalTTTQMLabel">
              {{$t('multidisciplinary_board.lbl_surgery_report')}}
            </h5>
            <button
              type="button"
              class="close bg-white border-0"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <TTTQMForm
            :curr_disease="mdt_disease_for_concluding"
            :room_info="room"
            @refresh="refreshDisease"
          />
        </div>
      </div>
    </div>

    <MdtDiseaseDetail :mdt_disease_id="showing_disease" />
    <ViewBienBan :mdt_disease="viewing_mdt_disease" />

    <ModalAddDisease
      v-if="room != {} && !!room"
      @refresh="refreshDisease"
      :room="room"
    />
    <div class="info-wraper box-shadow" v-show="show_info">
      <div class="info-wraper-backdrop" @click="show_info ^= show_info"></div>
      <div class="info-wraper-content">
        <MdtInfo
          :room_info="room"
          :room_members="room_members"
          :checkin_members="checkin_members"
          @hideInfo="hideInfo"
        />
      </div>
    </div>
    <div class="info-wraper" v-show="show_disease">
      <div
        class="info-wraper-backdrop"
        @click="show_disease ^= show_disease"
      ></div>
      <div class="info-wraper-content box-shadow">
        <MdtDisease
          :mdt_diseases="mdt_diseases"
          @hideDisease="hideDisease"
          @refresh="refreshDisease"
          @showDiseaseDetail="showDiseaseDetail"
          @showViewBienBan="showViewBienBan"
          @getMdtDiseases="onGetMdtDiseases"
          @showAddDisease="showAddDisease"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TwilioVideoCall from '../VideoCall/TwilioVideoCall.vue'
import MdtInfo from '../../components/HoiChan/MdtInfo.vue'
import MdtDisease from '../../components/HoiChan/MdtDisease.vue'
import BienBan from '../../components/HoiChan/BienBan.vue'
import TTTQMForm from '../../components/HoiChan/TTTQMForm.vue'
import MdtDiseaseDetail from '../../components/HoiChan/MdtDiseaseDetail.vue'
import ViewBienBan from '../../components/HoiChan/ViewBienBan.vue'
import ModalAddDisease from '../../components/HoiChan/ModalAddDisease.vue'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'

export default {
  name: 'MdtRoom',
  components: { MdtInfo, MdtDisease, TwilioVideoCall, BienBan, MdtDiseaseDetail, ViewBienBan, ModalAddDisease, TTTQMForm },
  data () {
    return {
      show_info: false,
      show_disease: false,
      room_name: '',
      room: '',
      password: '',
      loading: false,
      locked: true,
      widthMdt: 0,
      widthOuter: 0,
      zoomValue: 100,
      mdtUtils,
      room_members: [],
      checkin_members: [],
      mdt_diseases: [],
      whisperRoom: null,
      joinedUser: [],
      currDisease: null,
      mdt_disease_for_concluding: null,
      showing_disease: null,
      viewing_mdt_disease: null,
      params_disease: {
        sort: 'created_at',
        sort_by: 'asc',
        status: null,
        search: '',
        page: 0,
        loading: false
      },
      start_time: null,
      started_seconds: 0
    }
  },
  watch: {
    $echo (e) {
      if (e) {
        this.onConnectSocket()
      }
    }
  },
  created () {
    let self = this
    window.addEventListener('beforeunload', function (e) {
      self.leaving(e)
    })
  },
  mounted () {
    if (!this.$route.params.name) this.goToList()
    this.room_name = this.$route.params.name
    this.showMdtRoomByName(this.$route.params.name)
  },
  methods: {
    leaving (e) {
      if (this.room && !this.locked) this.endMdt(this.room.id)
      e.preventDefault()
      e.returnValue = ''
    },
    refreshDisease () {
      this.getMdtDiseases(this.room.id)
      this.getMdtDiseaseInProcess(this.room.id)
      this.showAddDisease(false)
      this.showTTTQM(false)
    },
    showTTTQM (show) {
      this.mdt_disease_for_concluding = this.currDisease
      setTimeout(() => {
        window.$('#modalTTTQM').modal(show ? 'show' : 'hide')
      }, 100)
    },
    viewTTTQM () {
      if (this.mdt_disease_for_concluding?.operation) {
        window.open(this.mdt_disease_for_concluding.operation.pdf)
      }
    },
    async startMdt (id) {
      let self = this
      await this.$rf.getRequest('DoctorRequest').startMdtRoom(id).then((r) => {
        self.start_time = r.data?.start_time
        if (r.data?.start_time) {
          let now = window.moment()
          let stared = window.moment(r.data?.start_time)
          self.started_seconds = now.diff(stared, 'seconds') // 1
        } else {
          self.started_seconds = 0
        }
      })
    },
    async endMdt (id) {
      let self = this
      await this.$rf.getRequest('DoctorRequest').endMdtRoom(id).then((r) => {
        self.start_time = r.data?.start_time
      })
    },
    async showMdtRoomByName (name) {
      let self = this
      if (!name) return
      self.loading = true
      self.$rf.getRequest('DoctorRequest').showMdtRoomByName(name).then((r) => {
        self.room = r.data
        self.locked = r.data.locked
        if (!self.locked) {
          self.startMdt(r?.data?.id)
          self.getMdtMembers(r?.data?.id)
          self.getMdtCheckinMembers(r?.data?.id)
          self.getMdtDiseases(r?.data?.id)
          self.mdtCheckin(r?.data?.id)
          self.getMdtDiseaseInProcess(r?.data?.id)
          self.onConnectSocket()
        }
      }).catch(() => {
        this.goToList()
        this.$toast.open({
          message: 'Lấy thông tin phòng thất bại',
          type: 'error'
        })
      }).finally(() => {
        self.loading = false
      })
    },
    async getMdtMembers (id) {
      let self = this
      let params = {
        date: window.moment().format('DD-MM-YYYY')
      }
      self.$rf.getRequest('DoctorRequest').getMdtMembers(id, params).then((r) => {
        self.room_members = r.data
      })
    },
    onGetMdtDiseases (is_next, params) {
      this.params_disease.search = params.search
      this.params_disease.status = params.status
      this.getMdtDiseases(this.room.id, is_next)
    },
    async getMdtDiseases (id, is_next) {
      let self = this
      console.log(id)
      self.params_disease.loading = true
      let page = 1
      if (!is_next) {
        self.mdt_diseases = []
      } else {
        page = this.params_disease.page + 1
      }
      let params = {
        mdt_room_id: id,
        limit: 10,
        sort: this.params_disease.sort,
        sort_by: this.params_disease.sort_by,
        page: page
      }
      if (this.params_disease.status !== null) {
        params.status = this.params_disease.status
      }
      if (this.params_disease.search) {
        params.search = this.params_disease.search
      }

      self.$rf.getRequest('DoctorRequest').getMdtDiseases(params).then((r) => {
        if (r && r.data) {
          self.params_disease.page = r.data.current_page
          if (!is_next) {
            self.mdt_diseases = r.data
          } else {
            if (r.data?.count) {
              let old = self.mdt_diseases.data
              let newObj = r.data.data
              self.mdt_diseases.data = old.concat(newObj)
            }
          }
        }
      }).finally(() => {
        self.params_disease.loading = false
      })
    },
    async getMdtDiseaseInProcess (id) {
      let self = this
      self.$rf.getRequest('DoctorRequest').getMdtDiseaseInProcess(id).then((r) => {
        self.currDisease = r.data
      })
    },
    async getMdtCheckinMembers (id) {
      let self = this
      let params = {
        date: window.moment().format('YYYY-MM-DD'),
        mdt_room_id: id,
        limit: 1000
      }
      self.$rf.getRequest('DoctorRequest').getMdtCheckinMembers(params).then((r) => {
        self.checkin_members = r.data
      })
    },
    async mdtCheckin (id) {
      let self = this
      let params = {
        mdt_room_id: id,
        status: 1
      }
      await self.$rf.getRequest('DoctorRequest').mdtCheckin(params)
    },
    async joinMdtRoom () {
      let self = this
      if (!self.room) return
      if (!self.password) {
        alert('Vui lòng nhập mật khẩu!')
        return
      }
      let params = {
        password: self.password
      }
      self.$rf.getRequest('DoctorRequest').joinMdtRoom(self.room.id, params).then((r) => {
        self.locked = false
        self.getMdtMembers(self.room.id)
        self.getMdtCheckinMembers(self.room.id)
        self.getMdtDiseases(self.room.id)
        self.mdtCheckin(self.room.id)
        self.onConnectSocket()
      }).catch(() => {
      })
    },
    goToList () {
      this.$router.push({ name: 'ListMdt' })
    },
    getDateString (room) {
      if (!room) return
      let now = window.moment()
      let date = window.moment(room.date)
      let type = room.repeat_type
      if (type) {
        if (type === 1) {
          let setDate = null
          if (room.end_time) {
            setDate = mdtUtils.createDateTime(null, room.end_time)
          } else {
            setDate = mdtUtils.createDateTime(null, '23:59:59')
          }
          if (setDate.unix() > now.unix()) {
            return mdtUtils.getNameOfDay(now.day()) + ' ' + (room.start_time ? '(' + this.formatDMYHm(null, room.start_time) + ')' : '')
          } else {
            return mdtUtils.getNameOfDay(now.clone().add(1, 'day').day()) + ' ' + (room.start_time ? '(' + this.formatDMYHm(now.clone().add(1, 'day').format('YYYY-MM-DD'), room.start_time) + ')' : '')
          }
        } else if (type === 2) {
          let setDate = null
          if (date.day() === now.day()) {
            if (room.end_time) {
              setDate = mdtUtils.createDateTime(null, room.end_time)
            } else {
              setDate = mdtUtils.createDateTime(null, '23:59:59')
            }
            if (setDate.unix() > now.unix()) {
              return mdtUtils.getNameOfDay(now.day()) + ' ' + (room.start_time ? '(' + this.formatDMYHm(null, room.start_time) + ')' : '')
            } else {
              let nextDate = mdtUtils.getDateNextWeekByDay(date.day())
              return mdtUtils.getNameOfDay(nextDate.day()) + ' (' + nextDate.format('DD/MM/YYYY') + (room.start_time ? ' - ' + this.formatHm('2022-02-22 ' + room.start_time) + ')' : ')')
            }
          } else {
            let nextDate = mdtUtils.getDateNextWeekByDay(date.day())
            return mdtUtils.getNameOfDay(nextDate.day()) + ' (' + nextDate.format('DD/MM/YYYY') + (room.start_time ? ' - ' + this.formatHm('2022-02-22 ' + room.start_time) + ')' : ')')
          }
        }
      } else {
        return mdtUtils.getNameOfDay(date.day()) + ' ' + (room.start_time ? '(' + this.formatDMYHm(window.moment(room.date), room.start_time) + ')' : '(' + window.moment(room.date).format('DD/MM/YYYY') + ')')
      }
    },
    formatDMYHm (date, time) {
      return mdtUtils.createDateTime(date, time).format('DD/MM/YYYY - HH:mm')
    },
    formatHm (time) {
      return mdtUtils.createDateTime(time).format('HH:mm')
    },
    calculateZoom () {
      // let self = this
      // self.widthMdt = self.$refs.mdtWraper.clientWidth
      // self.widthOuter = (self.$refs.outer__zoom ? self.$refs.outer__zoom.clientWidth : 0)
      // const zoomVal = (self.widthOuter / self.widthMdt) * 100
      // self.zoomValue = zoomVal > 100 ? 100 : zoomVal
    },
    async onConnectSocket () {
      let self = this
      // Have this in case you stop running your laravel echo server
      if (this.$echo) {
        if (appUtils.getLocalToken()) {
          let user = appUtils.getLocalUser()
          self.whisperRoom = this.$echo.private('twl_room.' + self.room_name)
          self.whisperRoom.listen('RoomMessageWasReceived', function (_data) {
            const { message } = _data
            const { type, data } = JSON.parse(message)
            console.log(type, data)
            if (type === 'refreshMdtMember') {
              self.onRefreshMembers(data)
            }
            if (type === 'refreshMdtDiseases') {
              self.onUserChangeDiseaseStatus(data)
            }
          }).listenForWhisper('joinRoom', (e) => {
            if (e.id !== user.id) {
              console.log('joinRoom', e)
              self.onUserJoinRoom(e)
            }
            self.whisperRoom.whisper('userOnline', {
              id: user.id,
              doctor_id: user.doctor ? user.doctor.id : '',
              name: user?.doctor?.name
            })
            console.log('sendUserOnline', {
              id: user.id,
              doctor_id: user.doctor ? user.doctor.id : '',
              name: user?.doctor?.name
            })
          }).listenForWhisper('userOnline', (e) => {
            if (e.id !== user.id) {
              console.log('userOnline', e.id)
              self.onUserJoinRoom(e)
            }
            if (e.id === user.id) {
              // alert('Tài khoản của bạn đã tham gia cuộc gọi này ở thiết bị khác! Vui lòng kiểm tra và thử lại.')
            }
          }).listenForWhisper('quitRoom', (e) => {
            if (e.id !== user.id) {
              console.log('quitRoom', e)
              self.onUserQuitRoom(e)
            }
          }).listenForWhisper('trackStatus', (e) => {
            console.log(e)
            self.onUserTrack(e)
          })

          setTimeout(() => {
            self.whisperRoom.whisper('joinRoom', {
              id: user.id,
              doctor_id: user.doctor ? user.doctor.id : '',
              name: user?.doctor?.name
            })
            console.log('sendJoinRoom', {
              id: user.id,
              doctor_id: user.doctor ? user.doctor.id : '',
              name: user?.doctor?.name
            })
          }, 1000)
        }
      }
    },
    onUserJoinRoom (user_info) {
      let exist = this.joinedUser.find(u => u.id === user_info.id)
      if (!exist) {
        this.joinedUser.push(user_info)
      }
    },
    onUserQuitRoom (user_info) {
    },
    onUserChangeDiseaseStatus (user_info) {
      this.getMdtDiseases(this.room.id)
      this.getMdtDiseaseInProcess(this.room.id)
    },
    onUserTrack (user_info) {
    },
    onRefreshMembers (user_info) {
      this.getMdtCheckinMembers(this.room.id)
      this.getMdtMembers(this.room.id)
    },
    showBienBan (show) {
      this.mdt_disease_for_concluding = this.currDisease
      setTimeout(() => {
        window.$('#modalAddBienBan').modal(show ? 'show' : 'hide')
      }, 100)
    },
    showDiseaseDetail (id) {
      let routeData = this.$router.resolve({ name: 'MdtDiseaseDetail', params: { id: id } })
      window.open(routeData.href, '_blank')
    },
    showDetailDisease (show) {
      window.$('#modalViewMdtDisease').modal(show ? 'show' : 'hide')
    },
    showViewBienBan (is_edit, mdt_disease) {
      if (!is_edit) {
        if (mdt_disease.concluding.pdf) window.open(mdt_disease.concluding.pdf)
      } else {
        this.mdt_disease_for_concluding = mdt_disease
        console.log(mdt_disease)
        setTimeout(() => {
          window.$('#modalAddBienBan').modal('show')
        }, 100)
      }
    },
    showAddDisease (show) {
      if (show) alert('Tính năng này đang phát triển')
      // window.$('#modalAddDisease').modal(show ? 'show' : 'hide')
    },
    hideInfo () {
      this.show_info = false
    },
    showInfo () {
      this.show_info = !this.show_info
      this.hideDisease()
    },
    hideDisease () {
      this.show_disease = false
    },
    showDisease () {
      this.show_disease = !this.show_disease
      this.hideInfo()
    }
  },
  destroyed () {
    this.endMdt(this.room.id)
    this.$echo.leave('twl_room.' + this.room_name)
  }
}
</script>

<style scoped>
.mdt-wraper {
  /* width: 1920px !important; */
  height: 100vh !important;
}
.mdt-container {
  height: 100vh;
}
.bg-252A30 {
  background-color: #252a30 !important;
}
.info-wraper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 199;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
}
.info-wraper-content {
  padding: 16px;
  width: 100%;
  max-width: 546px;
  height: 100vh;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.info-wraper-backdrop {
  width: calc(100% - 546px);
  height: 100vh;
}
</style>